
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const MapBackground = ({ children }) => {
    const data = useStaticQuery(graphql`
      query {
        allWordpressWpMedia(filter: {title: {eq: "map-background-contact"}}) {
            nodes {
                source_url
            }
        }
      }
    `)

    let mapImage = data.allWordpressWpMedia.nodes[0].source_url;

    return (
        <>
         <Img src={mapImage} alt=""  tabIndex={-1}/>
        </>
    )
}

export default MapBackground
